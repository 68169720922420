import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

interface DataDisplayTableProps {
  data: object[];
  columnNames: string[];
  dataTransportFunc: (data: object) => void;
}

export default function DataDisplayTable({
  data,
  columnNames,
  dataTransportFunc
}: DataDisplayTableProps): JSX.Element {
  const keyArray: string[] = data.length !== 0 ? Object.keys(data[0]) : [];
  const [selectedRow, setSelectedRow] = useState<object>({});
  const rowClickHandler = (data: object): void => {
    if (data === selectedRow) {
      setSelectedRow({});
      dataTransportFunc({});
    } else {
      setSelectedRow(data);
      dataTransportFunc(data);
    }
  };

  return (
    <>
      <TableContainer style={{ margin: 0, height: "300px" }} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              {columnNames.map((name: string, index: number) => (
                <React.Fragment key={index}>
                  {index === 0 ? (
                    <TableCell>{name}</TableCell>
                  ) : (
                    <TableCell align="right">{name}</TableCell>
                  )}
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ maxHeight: "250px" }}>
            {data.map((datum: any) => (
              <TableRow
                key={datum[keyArray[0]]}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                selected={datum === selectedRow}
                onClick={() => rowClickHandler(datum)}
              >
                {keyArray.map((keyName: string, index: number) => (
                  <React.Fragment key={index}>
                    {index === 0 ? (
                      <TableCell component="th" scope="row">
                        {datum[keyName]}
                      </TableCell>
                    ) : (
                      <TableCell align="right">{datum[keyName]}</TableCell>
                    )}
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
