import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function Logo(): JSX.Element {
  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        <Link href="/" underline="none" color="inherit">
          NTT DATA Eats
        </Link>
      </Typography>
    </>
  );
}
