import * as React from "react";
import { Polyline } from "react-leaflet";
import L from "leaflet";

interface RouteLineProps {
  vertexCoordinates: Array<[number, number]>;
}

// Mapの子コンポーネントとして使用する
export default function RouteLine({ vertexCoordinates }: RouteLineProps): JSX.Element {
  // 頂点の緯度経度のデータ型変換
  const vertexLatlngs: L.LatLng[] = [];
  for (const vertexCoordinate of vertexCoordinates) {
    vertexLatlngs.push(L.latLng(vertexCoordinate));
  }

  return <Polyline positions={vertexLatlngs} />;
}
