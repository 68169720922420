import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Top from "./components/pages/Top";
import What from "./components/pages/What";
import How from "./components/pages/How";
import Where from "./components/pages/Where";
import Sdgs from "./components/pages/Sdgs";
import Contact from "./components/pages/Contact";

export default function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/what" element={<What />} />
        <Route path="/how" element={<How />} />
        <Route path="/where" element={<Where />} />
        <Route path="/sdgs" element={<Sdgs />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}
