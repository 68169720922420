import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Header from "../organisms/Header";
import Footer from "../organisms/Footer";

interface DefaultTemplateProps {
  children: React.ReactNode;
}

export default function DefaultTemplate({ children }: DefaultTemplateProps): JSX.Element {
  return (
    <>
      <Container maxWidth="md">
        <Header />
        <Box sx={{ py: 1 }}>{children}</Box>
        <Footer />
      </Container>
    </>
  );
}
