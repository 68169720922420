import * as React from "react";
import Box from "@mui/material/Box";

interface DisplayContentTitleProps {
  title: string;
}

export default function DisplayContentTitle({ title }: DisplayContentTitleProps): JSX.Element {
  return (
    <>
      <Box component="h4" sx={{ textAlign: "center" }}>
        {title}
      </Box>
    </>
  );
}
