import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

interface LoadingProps {
  open: boolean;
  style: object;
}

export default function Loading({ open, style }: LoadingProps): JSX.Element {
  return (
    <Backdrop sx={{ color: "#ffffff", zIndex: (theme) => theme.zIndex.drawer - 1 }} open={open} style={style}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
