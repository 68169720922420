import * as React from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import icon from "../../assets/images/markers/marker_blue.png";
import shadow from "leaflet/dist/images/marker-shadow.png";

interface MapMarkerBlueProps {
  positionCoordinate: [number, number];
  popupMsg: string;
}

// Mapの子コンポーネントとして使用する
export default function MapMarkerBlue({
  positionCoordinate,
  popupMsg
}: MapMarkerBlueProps): JSX.Element {
  const posisionLatLng = L.latLng(positionCoordinate);
  const iconDesign = L.icon({
    iconUrl: icon,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
    shadowUrl: shadow,
    shadowAnchor: [10, 40]
  });

  return (
    <Marker position={posisionLatLng} icon={iconDesign}>
      <Popup>{popupMsg}</Popup>
    </Marker>
  );
}
