import * as React from "react";
import Typography from "@mui/material/Typography";

interface CaptionProps {
  text: string;
}

export default function Caption({ text }: CaptionProps): JSX.Element {
  return (
    <>
      <Typography variant="caption" align="center">
        {text}
      </Typography>
    </>
  );
}
