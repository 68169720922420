import * as React from "react";
import Paper from "@mui/material/Paper";

interface DisplayPaperProps {
  text: string;
}

export default function DisplayPaper({ text }: DisplayPaperProps): JSX.Element {
  return (
    <>
      <Paper elevation={2} sx={{ height: 40, width: "80%", mx: "auto", py: 1 }} style={{ textAlign: "center" }}>
        {text}
      </Paper>
    </>
  );
}
