import * as React from "react";
import Helmet from "react-helmet";

interface BrowserTabProps {
  title: string;
}

export default function BrowserTab({ title }: BrowserTabProps): JSX.Element {
  const tabTitle = title + " - NTT DATA Eats";
  return (
    <>
      <Helmet title={tabTitle} />
    </>
  );
}
