import * as React from "react";
import Box from "@mui/material/Box";
import Copyright from "../atoms/Copyright";

export default function Footer(): JSX.Element {
  return (
    <Box sx={{ mt: 2 }}>
      <Copyright />
    </Box>
  );
}
