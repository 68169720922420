import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function Copyright(): JSX.Element {
  return (
    <>
      <Typography variant="body2" color="text.secondary" align="center">
        {"© "}
        {new Date().getFullYear()}
        {" NTT DATA Group Corporation"}
      </Typography>
    </>
  );
}
