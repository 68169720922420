import * as React from "react";
import Box from "@mui/material/Box";
import DashBoardContentTitle from "../atoms/DashBoardContentTitle";
import DataDisplayTable from "../atoms/DataDisplayTable";
import DataGetError from "../atoms/DataGetError";
import Loading from "../atoms/Loading";

interface TableBoardProps {
  title: string;
  data: any;
  columnNames: string[];
  loadingStatus: boolean;
  errorStatus: boolean;
  dataTransportFunc: (data: object) => void;
}

export default function TableBoard({
  title,
  data,
  columnNames,
  loadingStatus,
  errorStatus,
  dataTransportFunc
}: TableBoardProps): JSX.Element {
  return (
    <>
      <Box sx={{ my: 4 }}>
        <DashBoardContentTitle title={title} />
        <Box style={{ position: "relative" }}>
          <DataDisplayTable
            data={data}
            columnNames={columnNames}
            dataTransportFunc={dataTransportFunc}
          />
          <Loading
            open={loadingStatus}
            style={{ position: "absolute", opacity: 0.5, minHeight: "250px" }}
          />
          <DataGetError
            open={errorStatus}
            style={{ position: "absolute", opacity: 0.5, minHeight: "250px" }}
          />
        </Box>
      </Box>
    </>
  );
}
