import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import DefaultTemplate from "../templates/DefaultTemplate";
import BrowserTab from "../organisms/BrowserTab";
import PageTitle from "../organisms/PageTitle";
import CO2AmountDisplay from "../organisms/CO2AmountDisplay";
import DeliveryTable from "../organisms/DeliveryTable";
import DeliveryRoute from "../organisms/DeliveryRoute";

export default function Sdgs(): JSX.Element {
  const pageTitle = "ダッシュボード";

  const [data, setData] = useState<object>([]);

  const dataSelectedHandler = (data: object): void => {
    setData(data);
  };

  return (
    <>
      <BrowserTab title={pageTitle} />
      <DefaultTemplate>
        <PageTitle title={pageTitle} />
        <Box sx={{ my: 4 }}>
          <CO2AmountDisplay />
          <DeliveryTable dataTransportFunc={dataSelectedHandler} />
          <DeliveryRoute data={data} />
        </Box>
      </DefaultTemplate>
    </>
  );
}
