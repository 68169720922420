import * as React from "react";
import Box from "@mui/material/Box";

interface PageTitleTextProps {
  title: string;
}

export default function PageTitleText({ title }: PageTitleTextProps): JSX.Element {
  return (
    <>
      <Box component="h1" sx={{ textAlign: "center" }}>
        {title}
      </Box>
    </>
  );
}
