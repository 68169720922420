import * as React from "react";
import { useEffect, useState } from "react";
import TableBoard from "../molecules/TableBoard";
import DeliveryHistoryDto from "../../features/dto/DeliveryHistoryDto";
import { DeliveryDisplayObj } from "../../features/objects/DeliveryDisplayObj";
import axios from "axios";

interface DeliveryTableProps {
  dataTransportFunc: (data: object) => void;
}

export default function DeliveryTable({ dataTransportFunc }: DeliveryTableProps): JSX.Element {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  const apiUrl = "/delivery-history";
  const columns: string[] = [
    "注文時刻",
    "注文ID",
    "店地点ID",
    "配達先地点ID",
    "CO2排出量",
    "配達時間"
  ];
  const [data, setData] = useState<DeliveryDisplayObj[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
  const [errorStatus, setErrorStatus] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(apiUrl, {
        params: {
          limit: 50
        },
        timeout: 30000
      })
      .then((res) => {
        const resultItems: DeliveryHistoryDto[] = res.data.result_content;
        setData(createDisplayData(resultItems));
        console.log("データ取得成功");
      })
      .catch((err) => {
        setErrorStatus(true);
        console.log(err);
        console.log("データ取得エラー");
        if (err.response !== undefined) {
          console.log(err.response);
        } else if (err.request !== undefined) {
          console.log(err.request);
        } else {
          console.log(err.message);
        }
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  }, []);
  console.log(data);

  const createDisplayData = (items: DeliveryHistoryDto[]): DeliveryDisplayObj[] => {
    const displayData: DeliveryDisplayObj[] = [];
    items.map((data: DeliveryHistoryDto) =>
      displayData.push({
        order_date: data.order_date,
        order_id: data.order_id,
        store_site_id: data.store_site_id,
        delivery_target_id: data.delivery_target_id,
        // path_id: data.path_id.items.join(),
        CO2_amount: data.CO2_amount,
        delivery_time: data.delivery_time
      })
    );
    return displayData;
  };

  return (
    <>
      <TableBoard
        title="配達履歴"
        data={data}
        columnNames={columns}
        loadingStatus={loadingStatus}
        errorStatus={errorStatus}
        dataTransportFunc={dataTransportFunc}
      />
    </>
  );
}
