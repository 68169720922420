import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import * as muiIcons from "@mui/icons-material";
import { MessageProps } from "./ChatTypes";

const Message: React.FC<MessageProps> = ({ message, name, type }) => {
  let icon;
  let iconColor;
  let color;
  if (type === "1") {
    icon = muiIcons.SupportAgent;
    iconColor = "#00A0E9";
    color = "#F8FBFF";
  } else {
    icon = muiIcons.AccountCircle;
    iconColor = "#B4CF9E";
    color = "#FFFDFA";
  }

  return (
      <ListItem alignItems="flex-start" style={{ backgroundColor: color }}>
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: iconColor }}>
          {React.createElement(icon)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
            <Typography
            variant="body2"
            color="text.primary"
            >
            {name}
            </Typography>
        }
        secondary={
            <Typography
              sx={{ display: "inline" }}
              variant="body1"
              component="span"
              color="text.primary"
              style={{ whiteSpace: "pre-line" }}
            >
              {message}
            </Typography>
        }
      />
    </ListItem>
  );
};
export default Message;
