import * as React from "react";
import { useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "../../assets/css/Leaflet.css";

interface MapProps {
  zoom: number;
  center: [number, number];
  children: React.ReactNode;
}

interface MoveCenterProps {
  center: [number, number];
}

// Mapの中心移動ファンクション
function MoveCenter({ center }: MoveCenterProps): null {
  const map: L.Map = useMap();
  useEffect(() => {
    map.setView(L.latLng(center));
  }, [center]);

  return null;
}

export default function Map({ zoom, center, children }: MapProps): JSX.Element {
  // 中心の緯度経度のデータ型変換
  const centerLatLng: L.LatLng = L.latLng(center);

  return (
    <>
      <MapContainer center={centerLatLng} zoom={zoom} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {children}
        <MoveCenter center={center} />
      </MapContainer>
    </>
  );
}
