import React, { useState } from "react";
import { MessageProps, AddList, apiResponse } from "./ChatTypes";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";

const ChatForm: React.FC<AddList> = (props) => {
  const [userInput, setUserInput] = useState<MessageProps>({ message: "", name: "ユーザー", type: "2" });

  const [loadFlg, setLoadFlg] = useState(false);

  // APIのエンドポイント
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  const apiUrl = "/conversation";

  const handleClick = (): void => {
    preProcess();
    callAPI();
  };

  const preProcess = (): void => {
    setLoadFlg(true);
    props.addMessageList([userInput]);

    setUserInput({ message: "", name: "ユーザー", type: "2" });
  };

  const updateList = (response: string): void => {
    const result: MessageProps = {
      message: response, name: "カスタマーセンター", type: "1"
    };
    props.addMessageList([userInput, result]);
    setLoadFlg(false);
  };

  const callAPI = (): void => {
    try {
      axios
        .get<apiResponse>(apiUrl, {
        params: {
          user_input: userInput.message
        },
        timeout: 300000
      }
      )
        .then(res => {
          console.log(res);
          updateList(res.data.result_content.response);
        })
        .catch(err => {
          console.log(err);
          updateList("エラーが発生しました");
        });
    } catch (error) {
      console.log(error);
      updateList("エラーが発生しました");
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "flex-end" }}>
      <TextField
        multiline
        rows={3}
        style={{ flex: "1 1 auto", resize: "none" }}
        value={ userInput.message }
        onChange={(e) => setUserInput({ ...userInput, message: e.target.value })}
      />
      <Button
        onClick={handleClick}
        disabled = {loadFlg}
        style={{ flex: "0 0 auto", height: "30px", marginLeft: "10px" }}
        variant="contained"
        endIcon={<SendIcon />}
        >
          Send
      </Button>
      </div>
  );
};
export default ChatForm;
