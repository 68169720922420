import * as React from "react";
import DefaultTemplate from "../templates/DefaultTemplate";
import BrowserTab from "../organisms/BrowserTab";
import PageTitle from "../organisms/PageTitle";

export default function Contact(): JSX.Element {
  const pageTitle = "ご利用の仕方";
  return (
    <>
      <BrowserTab title={pageTitle} />
      <DefaultTemplate>
        <PageTitle title={pageTitle} />
      </DefaultTemplate>
    </>
  );
}
