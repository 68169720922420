import React, { useEffect, useState } from "react";
import ChatLog from "./ChatLog";
import ChatForm from "./ChatForm";
import { MessageProps } from "./ChatTypes";

const ChatArea: React.FC = () => {
  const [messageList, setMessageList] = useState<MessageProps[]>([]);

  const addMessageList = (item: MessageProps[]): void => {
    setMessageList([...messageList, ...item]);
  };

  useEffect(() => {
    const initialMessage: MessageProps[] = [{
      message: "何かお困りでしょうか",
      name: "カスタマーセンター",
      type: "1"
    }];
    setMessageList(initialMessage);
  }, []);

  return (
  <div style={{ width: "100%" }}>
    <div style={{ margin: "50px" }}>
        <ChatLog list={messageList} />
    </div>
    <div style={{ margin: "50px" }}>
        <ChatForm
          addMessageList={addMessageList}
        />
    </div>
  </div>
  );
};

export default ChatArea;
