import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import DashBoardContentTitle from "../atoms/DashBoardContentTitle";
import Loading from "../atoms/Loading";
import DataGetError from "../atoms/DataGetError";

interface PapersBoardProps {
  title: string;
  children: React.ReactNode;
  loadingStatus: boolean;
  errorStatus: boolean;
}

export default function PapersBoard({
  title,
  children,
  loadingStatus,
  errorStatus
}: PapersBoardProps): JSX.Element {
  return (
    <>
      <Box sx={{ my: 4 }}>
        <DashBoardContentTitle title={title} />
        <Paper elevation={0} variant="outlined" square style={{ position: "relative" }}>
          <Loading open={loadingStatus} style={{ position: "absolute", opacity: 0.5 }} />
          <DataGetError open={errorStatus} style={{ position: "absolute", opacity: 0.5 }} />
          {children}
        </Paper>
      </Box>
    </>
  );
}
