import * as React from "react";
import Backdrop from "@mui/material/Backdrop";

interface DataGetErrorProps {
  open: boolean;
  style: object;
}

export default function DataGetError({ open, style }: DataGetErrorProps): JSX.Element {
  return (
    <Backdrop
      sx={{
        color: "#ff0000",
        bgcolor: "#ffffff",
        zIndex: (theme) => theme.zIndex.drawer - 1
      }}
      open={open}
      style={style}
    >
      データの取得に失敗しました
    </Backdrop>
  );
}
