import * as React from "react";
import Grid from "@mui/material/Grid";
import DisplayPaper from "../atoms/DisplayPaper";
import Caption from "../atoms/Caption";
import DisplayContentTitle from "../atoms/DisplayContentTitle";
import PaperDisplayObj from "../../features/objects/PaperDisplayObj";

interface PapersItemProps {
  title: string;
  data: PaperDisplayObj[];
}

export default function PapersItem({ title, data }: PapersItemProps): JSX.Element {
  const numOfData: number = data.length;
  let dataWidth = 0;
  if (numOfData !== 0) {
    if (numOfData > 4) {
      dataWidth = 3;
    } else {
      dataWidth = 12 / numOfData;
    }
  }

  return (
    <>
      <Grid container sx={{ my: 2 }}>
        <Grid item xs={3}>
          <DisplayContentTitle title={title} />
        </Grid>
        <Grid item xs={9}>
          {numOfData !== 0 && (
            <Grid container rowSpacing={2}>
              {data.map((datum: any) => (
                <Grid key={datum.caption} item xs={dataWidth} style={{ textAlign: "center" }}>
                  <Caption text={datum.caption} />
                  <DisplayPaper text={datum.content} />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
