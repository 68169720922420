import * as React from "react";
import Box from "@mui/material/Box";
import Logo from "../atoms/Logo";

export default function Header(): JSX.Element {
  return (
    <Box sx={{ p: 2 }}>
      <Logo />
    </Box>
  );
}
