import * as React from "react";
import { useEffect, useState } from "react";
import PapersBoard from "../molecules/PapersBoard";
import PapersItem from "../molecules/PapersItem";
import CO2AmountResponseDto from "../../features/dto/CO2AmountResponseDto";
import PaperDisplayObj from "../../features/objects/PaperDisplayObj";
import axios from "axios";

export default function CO2AmountDisplay(): JSX.Element {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  const apiUrl: string = "/total-co2-amount/summary";

  const [dailyData, setDailyData] = useState<PaperDisplayObj[]>([]);
  const [weeklyData, setWeeklyData] = useState<PaperDisplayObj[]>([]);
  const [monthlyData, setMonthlyData] = useState<PaperDisplayObj[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
  const [errorStatus, setErrorStatus] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(apiUrl, { timeout: 30000 })
      .then((res) => {
        const resultItem: CO2AmountResponseDto = res.data.result_content;
        setDailyData([
          {
            caption: "本日",
            content: resultItem.today_total.toString()
          },
          {
            caption: "前日",
            content: resultItem.yesterday_total.toString()
          }
        ]);
        setWeeklyData([
          {
            caption: "今週",
            content: resultItem.this_week_total.toString()
          },
          {
            caption: "先週",
            content: resultItem.last_week_total.toString()
          }
        ]);
        setMonthlyData([
          {
            caption: "今月",
            content: resultItem.this_month_total.toString()
          },
          {
            caption: "先月",
            content: resultItem.last_month_total.toString()
          }
        ]);
        console.log("データ取得成功");
      })
      .catch((err) => {
        setErrorStatus(true);
        console.log("データ取得エラー");
        if (err.response !== undefined) {
          console.log(err.response);
        } else if (err.request !== undefined) {
          console.log(err.request);
        } else {
          console.log(err.message);
        }
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  }, []);
  console.log(dailyData);
  console.log(weeklyData);
  console.log(monthlyData);

  return (
    <>
      <PapersBoard title="CO2排出量" loadingStatus={loadingStatus} errorStatus={errorStatus}>
        <PapersItem title="日次累計(kg-CO2)" data={dailyData} />
        <PapersItem title="週次累計(kg-CO2)" data={weeklyData} />
        <PapersItem title="月次累計(kg-CO2)" data={monthlyData} />
      </PapersBoard>
    </>
  );
}
