import * as React from "react";
import Box from "@mui/material/Box";
import PageTitleText from "../atoms/PageTitleText";

interface PageTitleProps {
  title: string;
}

export default function PageTitle({ title }: PageTitleProps): JSX.Element {
  return (
    <>
      <Box sx={{ my: 2 }}>
        <PageTitleText title={title} />
      </Box>
    </>
  );
}
