export interface DeliveryDisplayObj {
  [key: string]: any;
  order_date: string;
  order_id: string;
  store_site_id: number;
  delivery_target_id: number;
  // path_id: string,
  CO2_amount: number;
  delivery_time: string;
}

export function isDeliveryDisplayObj(arg: any): arg is DeliveryDisplayObj {
  return (
    arg !== null &&
    typeof arg === "object" &&
    typeof arg.order_date === "string" &&
    typeof arg.order_id === "string" &&
    typeof arg.store_site_id === "number" &&
    typeof arg.delivery_target_id === "number" &&
    typeof arg.CO2_amount === "number" &&
    typeof arg.delivery_time === "string"
  );
}
