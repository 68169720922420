import React, { useRef, useEffect } from "react";
import Message from "./Message";
import { ChatLogProps } from "./ChatTypes";
import Box from "@mui/material/Box";

const ChatLog: React.FC<ChatLogProps> = ({ list }) => {
  const boxRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (boxRef.current != null) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  }, [list]);

  return (
    <Box
    ref={boxRef}
    sx={{
      width: "100%",
      height: "500px",
      overflow: "hidden",
      overflowY: "scroll",
      backgroundColor: "#F9F9F9"
    }}
    >
        <div>
            {list.map((item: any, index: any) => (
                <Message key={index} message={item.message} name={item.name} type={item.type} />
            ))}
        </div>
    </Box>
  );
};
export default ChatLog;
