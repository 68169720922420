import * as React from "react";
import Box from "@mui/material/Box";
import DashBoardContentTitle from "../atoms/DashBoardContentTitle";
import Map from "../atoms/Map";
import MapMarkerBlue from "../atoms/MapMarkerBlue";
import MapMarkerRed from "../atoms/MapMarkerRed";
import RouteLine from "../atoms/RouteLine";

interface RouteDisplayMapBoardProps {
  title: string;
  startEndPoints: Array<[number, number]>;
  startEndPopupMsgs: string[];
  route: Array<[number, number]>;
  zoom: number;
  center: [number, number];
}

export default function RouteDisplayMapBoard({
  title,
  startEndPoints,
  startEndPopupMsgs,
  route,
  zoom,
  center
}: RouteDisplayMapBoardProps): JSX.Element {
  // 起点のポップアップメッセージ設定
  const startPopupMsg = startEndPopupMsgs.length > 0 ? startEndPopupMsgs[0] : "";
  // 終点のポップアップメッセージ設定
  const endPopupMsg = startEndPopupMsgs.length > 1 ? startEndPopupMsgs[1] : "";

  return (
    <>
      <Box sx={{ my: 4 }}>
        <DashBoardContentTitle title={title} />
        <Map zoom={zoom} center={center}>
          {startEndPoints.length > 1 ? (
            <>
              <MapMarkerBlue positionCoordinate={startEndPoints[0]} popupMsg={startPopupMsg} />
              <MapMarkerRed positionCoordinate={startEndPoints[1]} popupMsg={endPopupMsg} />
            </>
          ) : null}
          <RouteLine vertexCoordinates={route} />
        </Map>
      </Box>
    </>
  );
}
