import * as React from "react";
import { useState, useEffect } from "react";
import MapBoard from "../molecules/RouteDisplayMapBoard";
import { isDeliveryDisplayObj } from "../../features/objects/DeliveryDisplayObj";
import TownInfoDto from "../../features/dto/TownInfoDto";
import axios from "axios";

interface DeliveryRouteProps {
  data: object;
}

export default function DeliveryRoute({ data }: DeliveryRouteProps): JSX.Element {
  // 初期中心位置
  const centerDefault: [number, number] = [35.6559068, 139.7948658];
  // 初期ズームレベル
  const zoomLevel: number = 13;
  // APIのエンドポイント
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  const apiUrl = "/get-path-coordinate";

  // 起点・終点の座標の配列
  const [startEndPoints, setStartEndPoints] = useState<Array<[number, number]>>([]);
  // 中心の座標
  const [center, setCenter] = useState<[number, number]>(centerDefault);
  // 表示するルートの経由点(起点/終点含む)の座標の配列
  const [deliveryRoute, setDeliveryRoute] = useState<Array<[number, number]>>([]);
  // 起点/終点のポップアップメッセージ
  const [startEndExpressions, setStartEndExpressions] = useState<string[]>([]);

  useEffect(() => {
    if (isDeliveryDisplayObj(data)) {
      // DeliveryDisplayObjが渡された場合
      axios
        .get(apiUrl, {
          params: {
            order_id: data.order_id
          },
          timeout: 30000
        })
        .then((res) => {
          // 結果取得
          const resultItems: TownInfoDto[] = res.data.result_content;
          const pathLength: number = resultItems.length;

          // 起点の緯度経度
          const start: [number, number] = [resultItems[0].latitude, resultItems[0].longitude];

          // 終点の緯度経度
          const end: [number, number] = [
            resultItems[pathLength - 1].latitude,
            resultItems[pathLength - 1].longitude
          ];

          // 起点/終点の座標情報を設定
          setStartEndPoints([start, end]);

          // 中心(起点と終点の中点)の座標情報を設定
          setCenter([(start[0] + end[0]) / 2, (start[1] + end[1]) / 2]);

          // 経由点の座標情報を設定
          const routeCoordinates: Array<[number, number]> = [];
          for (const transitPoint of resultItems) {
            routeCoordinates.push([transitPoint.latitude, transitPoint.longitude]);
          }
          setDeliveryRoute(routeCoordinates);

          // 起点/終点のIDを設定
          setStartEndExpressions([
            "店地点ID：" + String(resultItems[0].town_id),
            "配達先地点ID：" + String(resultItems[pathLength - 1].town_id)
          ]);
          console.log("データ取得成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("データ取得エラー");
          if (err.response !== undefined) {
            console.log(err.response);
          } else if (err.request !== undefined) {
            console.log(err.request);
          } else {
            console.log(err.message);
          }
        });
    } else {
      // DeliveryDisplayObj以外が渡された(データ未選択)場合
      setStartEndPoints([]);
      setStartEndExpressions([]);
      setDeliveryRoute([]);
    }
  }, [data]);

  return (
    <>
      <MapBoard
        title="配達経路"
        startEndPoints={startEndPoints}
        startEndPopupMsgs={startEndExpressions}
        route={deliveryRoute}
        zoom={zoomLevel}
        center={center}
      />
    </>
  );
}
